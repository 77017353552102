<template>
  <el-dialog
    title="详情"
    :visible="companyDetailDialog"
    width="60%"
    class="editDialogs"
    @close="dialogClose"
    :close-on-click-modal="false"
    :destroy-on-close="false"
  >
    <div>
      <el-divider content-position="center">基本信息</el-divider>
      <ul class="bar" style="list-style-type: none">
        <li>
          <b>简称：</b>
          <span>{{ detail.name }}</span>
        </li>
        <li>
          <b>全称：</b>
          <span>{{ detail.fullName }}</span>
        </li>
        <li>
          <b>合作政府：</b>
          <span>{{ detail.govPartner }}</span>
        </li>
        <li>
          <b>资金规模：</b>
          <span
            >{{ detail.currencyType | currencyType }}
            {{ detail.fundScale }}</span
          >
        </li>

        <div
          class="width100 flexJsbAc"
          v-for="(item, index) in detail.partnerRelationList"
          :key="index"
        >
          <div class="width50 flexJfAc">
            <b>LP-{{ index + 1 }}：</b>
            <span>{{ item.partnerName }}</span>
          </div>
          <div class="width50 flexJfAc">
            <b>出资比例-LP{{ index + 1 }}：</b>
            <span>{{ item.investRatio }}%</span>
          </div>
        </div>
        <li>
          <b>投资范围：</b>
          <span> {{ detail.investScope }}</span>
        </li>
        <li>
          <b>可用资金规模：</b>
          <span
            >{{ detail.currencyType | currencyType }}
            {{ detail.usableFundScale }}</span
          >
        </li>
        <li>
          <b>单笔投资规模：</b>
          <span
            >{{ detail.currencyType | currencyType }}
            {{ detail.singleInvestScale }}</span
          >
        </li>
        <li>
          <b>投资领域：</b>
          <div
            style="padding: 0 2px"
            v-for="(item, index) in detail.investIndustryList"
            :key="index"
          >
            <div v-if="detail.investIndustryList.length == 1">{{ item }}</div>
            <div v-else>{{ index + 1 }}:{{ item }}</div>
          </div>
        </li>
        <li>
          <b>合作政府诉求：</b>
          <span>{{ detail.govPartnerDemand }}</span>
        </li>
      </ul>
      <el-divider content-position="center">企业工商信息</el-divider>

      <ul class="bar" style="list-style-type: none">
        <li>
          <b>公司名：</b>
          <span>{{ detail.regInfo.name }}</span>
        </li>
        <li>
          <b>法人：</b>
          <span> {{ detail.regInfo.legalPersonName }}</span>
        </li>
        <li>
          <b>公司logo:</b>
          <img :src="detail.regInfo.logo" width="80px" height="80px" alt />
        </li>

        <li>
          <b>注册资本：</b>
          <span>{{ detail.regInfo.regCapital }}</span>
        </li>
        <li>
          <b>注册时间：</b>
          <span> {{ detail.regInfo.fromTime | formatDateFilter }}</span>

          <!-- {{detail.fromTime}} -->
        </li>
        <li>
          <b>统一信用代码:</b>
          <span>{{ detail.regInfo.creditCode }}</span>
        </li>
        <li>
          <b>企业类型:</b>
          <span> {{ detail.regInfo.companyOrgType }}</span>
        </li>

        <li>
          <b>工商注册号:</b>
          <span> {{ detail.regInfo.regNumber }}</span>
        </li>
        <li>
          <b>组织机构代码:</b>
          <span>{{ detail.regInfo.orgNumber }}</span>
        </li>
        <li>
          <b>营业期限:</b>
          <span> {{ detail.regInfo.toTime | formatDateFilter }}</span>
        </li>
        <li>
          <b>行业:</b>
          <span> {{ detail.regInfo.industry }}</span>
        </li>
        
        <li>
          <b>注册地址:</b>
          <span>{{ detail.regInfo.regLocation }}</span>
        </li>
        <li class="width100">
          <b>经营范围:</b>
          <span>{{ detail.regInfo.businessScope }}</span>

        </li>
        <li>
          <b>联系电话:</b>
          <span>{{ detail.regInfo.phoneNumber }}</span>
        </li>
        <li>
          <b>联系电话:</b>
          <span>{{ detail.regInfo.phoneNumber }}</span>
        </li>
        <li>
          <b>轮次:</b>
          <span> {{ detail.rounds }}</span>
        </li>
        <li>
          <b>经营状态:</b>
          <span>{{ detail.regInfo.regStatus }}</span>
        </li>
      </ul>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogClose">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { formatDate } from "../../common/date";
import { companyDetail } from "@/api/fundCompany";
import { CURRENCY_TYPE_LIST } from "@/common/constants";

export default {
  name: "companyDetail",
  props: {
    companyDetailDialog: Boolean,
    id: String,
  },
  data() {
    return {
      detail: {},
      currencyTypeList: CURRENCY_TYPE_LIST, //货币类型(ISO4217)
      activeName: '',
    };
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    currencyType(value) {
      switch (value) {
        case "CNY":
          return "¥";
          break;
        case "USD":
          return "$";
          break;
      }
    },
  },
  computed: {},
  watch: {},
  mounted() {
    this.companyDetail();
  },
  methods: {
    dialogClose() {
      this.$emit("listenCompanyDetailClose", { type: "cancel", isClose: true });
    },
    companyDetail() {
      companyDetail(this.id).then((res) => {
        if (res.success) {
          this.detail = res.data;
        }
      });
    },
  },
  created() {},

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {},
};
</script>

<style lang="scss" scoped>
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 49%;
    // min-height: 30px;
    // line-height: 50px;
    padding: 5px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 150px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 160px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100% !important;
    div {
      width: 50%;
      b {
        min-width: 150px !important;
        text-align: right;
        align-self: baseline;
        float: left;

        span {
          display: inline;
          padding: 0 10px;
        }
      }
    }
  }
}
</style>