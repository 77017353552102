<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="editCompanyDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
  >
    <el-form
      :model="params"
      ref="params"
      :rules="rules"
      label-width="120px"
      class="addDailog"
    >
      <el-form-item label="简称" prop="name">
        <el-input
          type="text"
          v-model="params.name"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="公司全称" prop="fullName">
        <el-select
          v-model="params.fullName"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词进行工商查询"
          :remote-method="searchFullName"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in remoteFullNameOptions"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          > 
          </el-option>
        </el-select>
      </el-form-item> 
      <el-form-item label="合作政府" prop="govPartner">
        <el-input
          type="text"
          v-model="params.govPartner"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item label="资金规模" style="" prop="fundScale">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="number"
          show-word-limit
          v-model.number="params.fundScale"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <div
        class="el-form-item2 flexJfAc"
        v-for="(item, index) in params.partnerRelationList"
        :key="index"
      >
        <el-form-item prop="partnerName">
          <span slot="label"> LP-{{ index + 1 }} </span>
          <el-input type="text" v-model="item.partnerName"></el-input>
        </el-form-item>
        <el-form-item prop="investRatio">
          <span slot="label"> 出资比例-LP{{ index + 1 }} </span>
          <el-input
            type="text"
            v-model="item.investRatio"
            style="width: 73%; float: left"
          ></el-input> %
          <div class="addContributionRatio">
            <i
              @click="removeContributionRatio(index)"
              class="el-icon-remove-outline"
            ></i>
            <i
              @click="addContributionRatio"
              class="el-icon-circle-plus-outline"
            ></i>
          </div>
        </el-form-item>
      </div>

      <el-form-item label="投资范围" prop="investScope">
        <el-input
          type="text"
          v-model="params.investScope"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="可用资金规模" prop="usableFundScale">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="number"
          show-word-limit
          v-model.number="params.usableFundScale"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="单笔投资规模" prop="singleInvestScale">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="number"
          show-word-limit
          v-model.number="params.singleInvestScale"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投资领域" prop="investIndustryList">
        <el-select
          v-model="params.investIndustryList"
          filterable
          placeholder="请选择"
          multiple
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in industryList"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        class="el-form-item2"
        label="合作政府诉求"
        prop="govPartnerDemand"
      >
        <el-input
          type="textarea"
          :rows="3"
          autosize
          v-model="params.govPartnerDemand"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addCompany, editCompany, companyDetail,searchCompany } from "@/api/fundCompany";
import { CURRENCY_TYPE_LIST, INDUSTRY_LIST } from "@/common/constants";

export default {
  name: "editCompany",
  props: {
    type: String,
    editCompanyDialog: Boolean,
    id: String,
    companyType: String, 
  },
  data() {
    return {
      title: null,
      rules: {
         name: [
          { required: true, message: "请输入公司简称", trigger: "blur" },
        ],
        fullName: [
          { required: true, message: "请输入公司全称", trigger: "blur" },
        ],
        rounds: [
          { required: false, message: "请选择融资阶段", trigger: "change" },
        ],
      },
      restaurants: [],
      params: {
        type: this.companyType, //公司类型：0 默认，1 基金公司，2 供应链金融公司
        partnerRelationList: [
          {
            partnerName: null,
            investRatio: null,
          },
        ],
      },
      currencyTypeList: CURRENCY_TYPE_LIST, //货币类型(ISO4217)
      industryList: INDUSTRY_LIST, //投资领域
      remoteFullNameOptions: [],
    };
  },
  computed: {},
  created() {
    if (this.type == "edit") {
      this.title = "编辑";
      this.companyDetail(this.id);
    } else if (this.type == "add") {
      this.title = "新增";
    }
  },
  mounted() {},
  methods: {
    searchFullName(query) {
      if (query.length > 0) {
        searchCompany(query).then((res) => {
          //查找公司
          if (res.success) {
            this.remoteFullNameOptions = res.data;
          }
        });
      } else {
        this.remoteFullNameOptions = [];
      }
    }, 
    addCompany() {
      addCompany(this.params).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "添加成功!" });
          this.params = {};
          this.$emit("listenEditCompanyClose", { type: "sure", isClose: true });
        }
      });
    },
    editCompany() {
      editCompany(this.params).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.params = {};
          this.$emit("listenEditCompanyClose", { type: "sure", isClose: true });
        }
      });
    },
    //确定
    sure() {
      let { serviceArea, id } = this.params;
      console.log(this.params);

      if (this.type == "add") {
        this.addCompany();
      } else if (this.type == "edit") {
        this.editCompany();
      }
    },

    addContributionRatio() {
      let one = {
        partnerName: null,
        investRatio: null,
        sort: null,
      };
      this.params.partnerRelationList.push(one);
    },
    removeContributionRatio(index) {
      if (index > 0) {
        this.params.partnerRelationList.splice(index, 1);
      }
    },
    companyDetail(id) {
      //判断是否回显
      companyDetail(id).then((res) => {
        if (res.success) {
          this.params = res.data;
        }
      });
    },
    resetForm() {
      this.$refs["params"].resetFields();
    },
    //弹窗关闭
    dialogClose() {
      this.params = {};
      this.$emit("listenEditCompanyClose", { type: "cancel", isClose: true });
    },
  },

  beforeCreate() {},
  beforeMount() {},
  watch: {
    msPd(n, o) {
      //监听新增|编辑传过来的对象
      //箭头函数  不然会发生this改变
      this.objProject(n);
    },
    deep: true,
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {},
};
</script>

<style lang="scss" scoped>
.addDailog {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .el-form-item2 {
    width: 100% !important;
    height: auto;
    text-align: left !important;
  }
  .el-form-item {
    text-align: left;
    width: 48%;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left;
      .el-input__inner {
        text-align: left !important;
      }
    }
    .addContributionRatio {
      width: 20% !important;
      float: right;
      text-align: center;
      i {
        margin: 0 2px;
      }
    }
  }
}
</style>