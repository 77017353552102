/*
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-31 11:17:14
 * @LastEditTime: 2022-01-20 18:35:16
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/minutesOfMeeting.js
 */
import request from '@/utils/request'

//详情
export function queryMeetingDetail(param) {
  return request({
    url: '/admin/meeting/findDetail',
    method: 'post',
    data: param
  })
}

//列表
export function queryMeetingList(param) {
  return request({
    url: '/admin/meeting/queryList',
    method: 'post',
    data: param
  })
}

//新增
export function addMeeting(param) {
  return request({
    url: '/admin/meeting/add',
    method: 'post',
    data: param
  })
}

//编辑
export function editMeeting(param) {
  return request({
    url: '/admin/meeting/edit',
    method: 'post',
    data: param
  })
}

//删除
export function deleteMeeting(param) {
  return request({
    url: '/admin/meeting/delete',
    method: 'post',
    data: {id: param}
  })
}

//项目的下拉列表
export function selectListMeetingProject(param) {
  return request({
    url: '/admin/project/select-list',
    method: 'post',
    data: param
  })
}

//条件查询投资人下拉列表
export function selectListInvestor(name,organizationId) {
  return request({
    url: '/admin/investor/selectList',
    method: 'post',
    data: {name: name,organizationId:organizationId}
  })
}

//项目的项目流程节点的下拉列表接口
export function selectListWorkflow(param) {
  return request({
    url: '/admin/project/workflow/detail/selectList',
    method: 'post',
    data: param
  })
}