import request from '@/utils/request'

//公司 start
export function getCompanyList(param) {
    return request({ //“type”: 公司类型：0 默认，1 基金公司，2 供应链金融公司
        url: '/admin/company/paging-list',
        method: 'post',
        data: param
    })
}
export function getCompanySelectList(param) {
    return request({ //“type”: 公司类型：0 默认，1 基金公司，2 供应链金融公司
        url: '/admin/company/select-list',
        method: 'post',
        data: param
    })
}
export function addCompany(param) {
    return request({
        url: '/admin/company/add',
        method: 'post',
        data: param
    })
}

export function editCompany(param) {
    return request({
        url: '/admin/company/edit',
        method: 'post',
        data: param
    })
}


export function companyDetail(param) {
    return request({
        url: '/admin/company/detail',
        method: 'post',
        data: {
            id: param,
        }
    })
}
export function deleteCompany(param) {
    return request({
        url: '/admin/company/delete',
        method: 'post',
        data: {
            id: param,
        }
    })
}
//公司 end

//公司-投资信息 start

export function getCompanyInvestList(param) {
    return request({
        url: 'admin/company/invest-info/paging-list',
        method: 'post',
        data: param
    })
} 
export function addCompanyInvest(param) {
    return request({
        url: '/admin/company/invest-info/add',
        method: 'post',
        data: param
    })
}

export function editCompanyInvest(param) {
    return request({
        url: '/admin/company/invest-info/edit',
        method: 'post',
        data: param
    })
}


export function companyInvestDetail(param) {
    return request({
        url: '/admin/company/invest-info/detail',
        method: 'post',
        data: {
            id: param,
        }
    })
}
export function deleteCompanyInvest(param) {
    return request({
        url: '/admin/company/invest-info/delete',
        method: 'post',
        data: {
            id: param,
        }
    })
}

//公司-投资信息 end

//公司投资估值记录 
export function getCompanyInvesValuationtList(param) {
    return request({
        url: '/admin/company/invest-info/valuation/paging-list',
        method: 'post',
        data:param
    })
}

//放款企业 lendingEnterprises  star
export function getLendingEnterprisesList(param) {
    return request({
        url: '/admin/company/loaner-info/paging-list',
        method: 'post',
        data: param
    })
} 
export function addLendingEnterprises(param) {
    return request({
        url: '/admin/company/loaner-info/add',
        method: 'post',
        data: param
    })
}

export function editLendingEnterprises(param) {
    return request({
        url: '/admin/company/loaner-info/edit',
        method: 'post',
        data: param
    })
}


export function lendingEnterprisesDetail(param) {
    return request({
        url: '/admin/company/loaner-info/detail',
        method: 'post',
        data: {
            id: param,
        }
    })
}
export function deleteLendingEnterprises(param) {
    return request({
        url: '/admin/company/loaner-info/delete',
        method: 'post',
        data: {
            id: param,
        }
    })
}

//放款企业 end

// 工商查询
export function searchCompany(param) {
    return request({
        url: '/admin/company/reg-info/select-list-by-name',
        method: 'post',
        data: {
            name: param,
        }
    })
}