<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="valuationHistoryDialog"
    center
    @close="dialogClose"
    width="700px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    append-to-body
  >
    <el-main>
      <!-- 表格 -->
      <el-table border :data="tableData">
        <el-table-column label="更新后估值" prop="investValuation">
          <template slot-scope="scope">
            <span
              >{{ scope.row.currencyType | currencyType }}
              {{ scope.row.investValuation }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="更新人"
          prop="valuationUserName"
        ></el-table-column>
        <el-table-column label="更新日期" prop="valuationTime" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.valuationTime | formatDateFilter }}</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>
      </div>
    </el-main>
  </el-dialog>
</template>

<script>
import { getCompanyInvesValuationtList } from "@/api/fundCompany";
import { formatDate } from "@/common/date";
export default {
  name: "valuationHistory",
  props: {
    valuationHistoryDialog: Boolean,
    id: String,
  },
  data() {
    return {
      title: "估值更新历史",
      tableData: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        investInfoId: this.id,
      },
    };
  },
  components: {},
  filters: {
        formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    currencyType(value) {
      switch (value) {
        case "CNY":
          return "¥";
          break;
        case "USD":
          return "$";
          break;
      }
    },
  },
  created() {},
  mounted() {
    this.getCompanyInvesValuationtList(1, this.pageInfo.pageSize);
  },
  //keep-alive的生命周期 ——进入组件
  activated() {
    console.log("activated2====");
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() {
    console.log("deactivated2====");
    this.getCompanyInvesValuationtList(1, this.pageInfo.pageSize);
  },
  methods: {
    //弹窗关闭
    dialogClose() {
      this.params = {};
      this.$emit("listenValuationHistoryClose", {
        type: "cancel",
        isClose: true,
      });
    },

    getCompanyInvesValuationtList(num, pageSize) {
      //项目库列表
      this.queryParams.pageNum = num;
      this.queryParams.pageSize = pageSize;
      getCompanyInvesValuationtList(this.queryParams).then((response) => {
        this.tableData = response.data;
        // console.log(this.tableData);
        if (response.total != null) {
          this.pageInfo.pageNum = response.pageNum;
          this.pageInfo.pageSize = response.pageSize;
          this.pageInfo.total = response.total;
          this.pageInfo.pageCount = response.pageCount;
        } else {
          this.pageInfo.total = 0;
        }
      });
    },

    handleSizeChange(val) {
      console.log(val);
      this.getCompanyInvesValuationtList(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.getCompanyInvesValuationtList(val, this.pageInfo.pageSize);
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}
</style>
